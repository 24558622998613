/* /* src/App.css

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.6;
  background: linear-gradient(135deg, #a0d2e2, #1e3c72);  
  color: #fff; 
}

h1, p {
  margin: 0;
  padding: 0;
}

h1 {
  text-align: center;
  margin-top: 50px;
} */


 
/* body {
  margin: 0;
  font-family: "Arial", sans-serif;
  background: linear-gradient(to bottom right, #02475e, #a2d9ff);
  color: #fff;
  text-align: center;
}

.coming-soon {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.hero {
  margin-top: 50px;
}

.logo {
  font-size: 3rem;
  font-weight: bold;
  color: #e0f7ff;
}

.tagline {
  font-size: 1.5rem;
  margin: 20px 0;
}

.offerings {
  margin: 40px 0;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
}

.offerings h2 {
  margin-bottom: 15px;
  font-size: 2rem;
}

.offerings ul {
  list-style: none;
  padding: 0;
}

.offerings li {
  font-size: 1.2rem;
  margin: 10px 0;
}

.subscription {
  margin: 40px 0;
}

.subscription h2 {
  font-size: 2rem;
  margin-bottom: 15px;
}

.subscription form {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.subscription input {
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: none;
}

.subscription button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #0288d1;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.subscription button:hover {
  background-color: #01579b;
}

footer {
  margin-top: 50px;
}

footer .social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  font-size: 1.5rem;
  margin: 10px 0;
}

footer p {
  margin: 10px 0;
  font-size: 0.9rem;
}  */


/* App.css */
body {
  margin: 0;
  font-family: "Arial", sans-serif;
  background: linear-gradient(to bottom right, #02475e, #a2d9ff);
  color: #fff;
  text-align: center;
}

.coming-soon {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1200px; /* Max width for the content */
  margin: 0 auto; /* Center the content horizontally with some margin */
}

.hero {
  margin-top: 50px;
}

.logo {
  font-size: 3rem;
  font-weight: bold;
  color: #e0f7ff;
}

.tagline {
  font-size: 1.5rem;
  margin: 20px 0;
}

.offerings {
  margin: 40px 0;
  width: 100%; /* Ensure the offerings section occupies full width */
}

.offerings-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr)); /* Make cards a little wider */
  gap: 20px;
  margin-top: 30px;
}

/* Offering Card Styles */
.offering-card {
  background: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  transition: transform 0.3s ease;
}

.offering-card:hover {
  transform: translateY(-5px);
}

.offering-card .icon {
  font-size: 3rem;
  color: #0288d1;
  margin-bottom: 15px;
}

.offering-card h3 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #e0f7ff;
}

.offering-card p {
  font-size: 1rem;
  color: #ccc;
}

/* Subscription Section */
.subscription {
  margin: 40px 0;
}

.subscription h2 {
  font-size: 2rem;
  margin-bottom: 15px;
}

.subscription form {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.subscription input {
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: none;
}

.subscription button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #0288d1;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.subscription button:hover {
  background-color: #01579b;
}

/* Footer */
footer {
  margin-top: 50px;
}

footer .social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  font-size: 1.5rem;
  margin: 10px 0;
}

footer p {
  margin: 10px 0;
  font-size: 0.9rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero {
    margin-top: 30px;
  }

  .logo {
    font-size: 2.5rem;
  }

  .tagline {
    font-size: 1.3rem;
  }

  .offerings-cards {
    grid-template-columns: 1fr 1fr; /* 2 columns on tablets */
  }

  .offering-card h3 {
    font-size: 1.5rem;
  }

  .offering-card p {
    font-size: 0.95rem;
  }

  .subscription h2 {
    font-size: 1.7rem;
  }

  .subscription input {
    font-size: 0.95rem;
    padding: 8px;
  }

  .subscription button {
    font-size: 0.95rem;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .logo {
    font-size: 2rem;
  }

  .tagline {
    font-size: 1.2rem;
  }

  .offerings-cards {
    grid-template-columns: 1fr; /* Single column on mobile */
  }

  .offering-card h3 {
    font-size: 1.3rem;
  }

  .offering-card p {
    font-size: 0.9rem;
  }

  .subscription h2 {
    font-size: 1.5rem;
  }

  .subscription input {
    font-size: 0.9rem;
    padding: 8px;
  }

  .subscription button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}

/* Laptop/large screen: Adjust the layout to make it more expansive */
@media (min-width: 1024px) {
  .coming-soon {
    max-width: 1300px; /* Increase max width to occupy more space on larger screens */
    padding: 30px; /* Add some extra padding */
  }

  .offerings-cards {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for laptops/desktops */
  }

  .offerings-cards {
    gap: 25px; /* Increase gap between cards */
  }

  .offering-card {
    padding: 30px; /* Increase padding inside the cards */
  }

  .offering-card h3 {
    font-size: 1.8rem;
  }

  .offering-card p {
    font-size: 1rem;
  }

  .subscription input {
    font-size: 1rem;
  }

  .subscription button {
    font-size: 1rem;
  }
}
