/* General Styles */
body {
    margin: 0;
    font-family: "Arial", sans-serif;
    background: linear-gradient(to bottom right, #003459, #a2d9ff);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .app-container {
    width: 100%;
    max-width: 800px;
    padding: 30px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    text-align: center;
  }
  
  /* Header */
  /* Header */
  header h1 {
    font-size: 2.5rem;
  }
  
  header p {
    max-width: 600px; /* Constrain text width */
    margin: 10px auto; /* Center align */
    font-size: 1.2rem;
    line-height: 1.6;
    word-wrap: break-word;
  }
  
  
  /* Tabs */
  .tab-navigation {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .tab-button {
    flex: 1;
    padding: 10px;
    font-size: 1.1rem;
    border: none;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.2);
    color: #e0f7ff;
    border-radius: 5px;
    margin: 0 5px;
  }
  
  .tab-button.active {
    background: #0288d1;
    color: #fff;
  }
  
  .tab-button:hover {
    background: rgba(255, 255, 255, 0.4);
  }
  
  /* Form Section */
  .form-section {
    margin: 20px 0;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }
  
  .form-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .form-label {
    font-weight: bold;
    margin-right: 10px;
  }
  
  .phone-input-wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .phone-prefix {
    padding: 10px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px 0 0 5px;
    font-size: 1rem;
  }
  
  input[type="text"] {
    flex: 1;
    padding: 10px;
    font-size: 1rem;
    border-radius: 0 5px 5px 0;
    border: none;
  }
  
  textarea {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    border-radius: 5px;
    border: none;
    resize: none;
  }
  
  /* Submit Button */
  .submit-btn {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    background: #0288d1;
    color: #fff;
    cursor: pointer;
  }
  
  .submit-btn:hover {
    background: #01579b;
  }
  
  /* Toast */
  .toast {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 15px 20px;
    border-radius: 5px;
    font-size: 1rem;
    color: #fff;
    animation: slideIn 0.5s ease, fadeOut 3s 2.5s ease;
  }
  
  .toast.success {
    background: #28a745;
  }
  
  .toast.error {
    background: #dc3545;
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(20%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    to {
      opacity: 0;
    }
  }