/*  
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

.header {
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  text-align: center;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
}

.card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: calc(20% - 40px);  
  margin: 10px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.card-image {
  width: 100%;
  border-radius: 4px;
}

.card-title {
  font-size: 1.5em;
  margin: 10px 0;
}

.card-description {
  color: #555;
}

.footer {
  text-align: center;
  padding: 10px;
  background-color: #333;
  color: white;
}

.home-title {
  font-size: clamp(2rem, 4vw, 3.5rem);
}
 */


 /* Reset margins, padding, and box-sizing */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  font-family: Arial, sans-serif; /* Add a default font */
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column; /* Helps with layout consistency */
}
